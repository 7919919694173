module.exports.API = {
    baseUrl: 'https://muenze-node.customers.specure.com:4430',
    coinsPath: 'coin',
    connection: {
        host: '0.0.0.0', // do not change or change to your public IP
        port: 3000,
    },
    createVitrinesOnStart: [1, 5, 31, 32, 33, 34, 41, 42, 43],
    filesPath: 'file/upload',
    groupPath: 'group',
    itemsPath: 'item',
    importFrom: {
        data: 'data',   // folder with vitrine items
        folder: 'import',   // root folder
        screensavers: 'screensavers',   // folder with screensavers
    },
    screenSaverPath: 'screen-saver',
    vitrinePath: 'vitrine',
}

module.exports.DB = {
    baseUrl: 'mongodb://mongo:27017/mint',
}

module.exports.FileStorage = {
    baseUrl: 'https://muenze-node.customers.specure.com',
    dpi: 44.5, // to resize coin images in accordance with their original size
    imagesPath: 'i',
    path:  './public',
    publicPath:  '/public/i',
    originalPath:  'original.png',
    thumbnailPath:  'thumbnail.png',
}

module.exports.ForeignAPI = {
    baseUrl: 'https://www.muenzeoesterreich.at/json/rupem_json',
    coinPath:  'products',
    jobs: {
        pricesUpdate: {
            cron: "*/30 * * * *",
            enabled: true,
            key: 'UPDATE_PRICES',
            updateAfterStart: false,
        },
        vitrineItemsUpdate: {
            cron: '0 30 15 * *',
            enabled: true,
            key: 'UPDATE_VITRINE_ITEMS',
            updateAfterStart: false,
        },
    },
    maxFileSize: 5 // in Mb
}

module.exports.Frontend = {
    baseUrl: 'https://muenze-node.customers.specure.com',
    coinWall: {
        idleTime: 5000, // time before gray overlay disappears
        moveToCenterTime: 300,
    },
    screenSaver: {
        idleTime: 60000, // 1 minute, in ms, idle time before starting to show the screensavers
        defaultDuration: 3000,
        fadingDuration: 400
    },
    units: {
        size: 'mm',
        weight: 'g'
    }
}

module.exports.Socket = {
    channel: 'vitrine',
    status: {
        clientConnected: 'CONNECTED',
        clientDisconnected: 'DISCONNECTED',
        coinsChanged: 'COINS_CHANGED',
        error: 'ERROR',
        itemsChanged: 'ITEMS_CHANGED',
        screenSaversChanged: 'SCREENS_CHANGED',
    }
}
