import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: '/en/vitrine/1', pathMatch: 'full',
  },
  {
    path: ':lang/vitrine', loadChildren: './vitrine/vitrine.module#VitrineModule',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
