import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'am-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'austrian-mint';

  @HostListener('mousewheel', ['$event'])
  @HostListener('DOMMouseScroll', ['$event'])
  preventCtrlScrollZoom($event) {
    if ($event.ctrlKey) {
      $event.preventDefault();
    }
  }
}
